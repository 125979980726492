import React from 'react';
import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
import App from './App';
import  './Than.css'; 
import { HelmetProvider } from 'react-helmet-async';
import ReactGA from "react-ga4";


const root = ReactDOM.createRoot(document.getElementById('root'));
ReactGA.initialize("G-MFFCJ3EFNH");
root.render(
  <React.StrictMode>

   <div className='index'>
   <HelmetProvider>
       <App/>
       </HelmetProvider>
   </div>
 
  </React.StrictMode>
);
const SendAnalytics = ()=> {
  ReactGA.send({
    hitType: "pageview",
    page: window.location.pathname,
  });
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals(SendAnalytics);
