
export const linker =  [
    {
        title: "home",
        url: '/',
        Cname: "nav-links",
        icon: "fa-solid  fa-house-user"
    },
    {
        title: "search",
        url: './search',
        Cname: "nav-links",
        icon: "fa-solid  fa-search"
    
    },  
 
    {
        title: "Nature 101.",
        url: "./view",
        Cname: "nav-links",
        icon: "fa-solid  fa-stream"
    
    },

    {
        title: "About",
        url: "./about",
        Cname: "nav-links",
    
    },

]