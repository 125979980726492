import React from 'react';




function ERROR() {
    return (
      <div className='lines'>
        <h1 className='head'>404</h1>
        <p className='head'> MISSING PAGE / ERROR </p>
      
      

     
       </div>
    );
  }
  
  export default ERROR;
  